import React from "react";
import PropTypes from "prop-types";
import Carousel from "../Carousel/Carousel";
import CarouselNavigationContent
    from "../CarouselNavigationContent/CarouselNavigationContent";
import { DEVICE_BREAKPOINTS } from "../../constants";

import { Link } from "gatsby";

import "./PortfolioCarousel.scss";

import background1_img from "../../assets/img/portfolio/bg_1.png";
import background2_img from "../../assets/img/portfolio/bg_2.png";
import background3_img from "../../assets/img/portfolio/bg_3.png";
import background4_img from "../../assets/img/portfolio/bg_4.png";
import background5_img from "../../assets/img/portfolio/bg_5.png";
import background6_img from "../../assets/img/portfolio/bg_6.png";

import preview1_img from "../../assets/img/portfolio/img_1.png";
import preview2_img from "../../assets/img/portfolio/img_2.png";
import preview3_img from "../../assets/img/portfolio/img_3.png";
import preview4_img from "../../assets/img/portfolio/img_4.png";
import preview5_img from "../../assets/img/portfolio/img_5.png";
import preview6_img from "../../assets/img/portfolio/img_6.png";

import restaurantPortfolioImg1
    from "../../assets/img/portfolio/restaurant-portfolio-1.png";
import restaurantPortfolioImg2
    from "../../assets/img/portfolio/restaurant-portfolio-2.png";
import restaurantPortfolioImg3
    from "../../assets/img/portfolio/restaurant-portfolio-3.png";

import PortfolioProject from "../PortfolioProject/PortfolioProject";

PortfolioCarousel.propTypes = {};

const DATA = [
    {
        _id: "1",
        previewBackground: background1_img,
        previewImage: preview1_img,
        title: "Driving+",
        images: []
    },
    {
        _id: "2",
        previewBackground: background2_img,
        previewImage: preview2_img,
        title: "11 Mirrors Rooftop Restaurant & Bar",
        images: [
            restaurantPortfolioImg1,
            restaurantPortfolioImg2,
            restaurantPortfolioImg3
        ]
    },
    {
        _id: "3",
        previewBackground: background6_img,
        previewImage: preview6_img,
        title: "UPSTART NETWORK",
        images: []
    },
    {
        _id: "4",
        previewBackground: background4_img,
        previewImage: preview4_img,
        title: "QLP",
        images: []
    },
    {
        _id: "5",
        previewBackground: background3_img,
        previewImage: preview3_img,
        title: "Центр Ridni",
        images: []
    },
    {
        _id: "6",
        previewBackground: background5_img,
        previewImage: preview5_img,
        title: "hiTurkey",
        images: []
    },
];

const {
    MOBILE_WINDOW_SIZE,
    TABLET_WINDOW_SIZE,
    LARGE_DESKTOP_WINDOW_SIZE
} = DEVICE_BREAKPOINTS;


function PortfolioCarousel( props ) {
    const carouselConfig = {
        loop: true,
        slidesPerView: "auto",

        breakpoints: {
            [MOBILE_WINDOW_SIZE]: {
                spaceBetween: 20
            },
            [TABLET_WINDOW_SIZE]: {
                spaceBetween: 40,
            },
            [LARGE_DESKTOP_WINDOW_SIZE]: {
                spaceBetween: 50
            }
        },
        pagination: {
            dynamicBullets: true,
            dynamicMainBullets: 4,
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: ( index, className ) => {
                return `<span class="${ className }"></span>`;
            }
        },
        navigation: {
            prevEl: ".swiper__btn--prev",
            nextEl: ".swiper__btn--next",
        }
    };

    const slides = DATA.map(( {
                                  _id,
                                  previewImage,
                                  title,
                                  previewBackground
                              } ) => {
        return (
            <PortfolioProject
                key={ _id }
                id={ _id }
                image={ previewImage }
                title={ title }
                background={ previewBackground }
            />
        );
    });

    return (
        <Carousel
            slides={ slides }
            swiperProps={ carouselConfig }
            swiperChildren={ <CarouselNavigationContent/> }
        />
    );
}

export default PortfolioCarousel;