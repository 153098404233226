import React from "react";

import {Link} from "gatsby";
import PropTypes from "prop-types";

import "./PortfolioProject.scss"

PortfolioProject.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired
};


function PortfolioProject({title, image, id, background}) {
    const backgroundStyles = {
        backgroundImage: `url(${background})`
    }

    return <Link className="portfolio-project" to={`/portfolio/${id}`}>
        <figure className="portfolio-project__img">
            <div style={backgroundStyles} />
            <img src={image} alt={title} title={title}/>
        </figure>
        <p className="portfolio-project__title">{title}</p>
    </Link>;
}


export default PortfolioProject;
