import React from 'react';
import PropTypes from 'prop-types';
import SwiperCore, {Navigation, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import "./Carousel.scss"

Carousel.propTypes ={
    swiperProps: PropTypes.object,
    slideProps: PropTypes.object,
    swiperChildren: PropTypes.element,
    slides: PropTypes.arrayOf(PropTypes.element),
};

SwiperCore.use([Navigation, Pagination]);

function Carousel(
    {
        swiperProps,
        slideProps,
        swiperChildren,
        slides
    }
) {
    const slideList = slides.map((slide, index) =>{
        return <SwiperSlide
            key={`${Date.now()}-${index}`}
            {...slideProps}
            children={slide}
        />
    });

    return (
        <Swiper
            {...swiperProps}
        >
            {slideList}
            {swiperChildren}
        </Swiper>
    );
}

export default Carousel;